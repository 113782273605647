import { makeStyles } from "@mui/styles";

const MessageStyles = () => {
  const styles = makeStyles((theme) => ({
    alert: {
      position: "fixed",
      zIndex: 999999,
      bottom: "0",
      right: "0",
      top: "auto",
      justifyContent: "flex-end",
      "@media (max-width: 700px)": {
        left: "0%",
      },
      "@media (min-width: 700px)": {
        left: "50%",
      },
      "@media (min-width: 1250px)": {
        left: "70%",
      },
    },
  }));
  return styles;
};

const ModalStyles = () => {
  const styles = makeStyles((theme) => ({
    odin: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "#00000096",
      zIndex: 3,
      maxHeight: "100%",
      "& .thor": {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "0px",
        background: "#FFFFFF",
        "& .magni": {
          position: "relative",
          left: 0,
          right: 0,
          height: "40px",
          display: "flex",
          justifyContent: "flex-end",
        },
      },
    },
  }));
  return styles;
};

const IconInputComponent = () => {
  const styles = makeStyles((theme) => ({
    odin: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      background: "#F7F7F7",
      color: "#838383",
      alignItems: "center",
      paddingLeft: "18px",
      borderRadius: "8px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
      "& .icon": {
        width: "13px",
        height: "13px",
      },
      "& .line": {
        width: "1px",
        height: "16px",
        background: "#D4D4D4",
        marginLeft: "15px",
      },
      "& .input": {
        fontFamily: "Lexend",
        background: "transparent",
        border: "none",
        fontStyle: "normal",
        color: "#838383",
        fontWeight: "500",
        fontSize: "12px",
        padding: "17px 19px 17px 15px",
        width: "100%",
        lineHeight: "15px",
      },
      "& .input:focus-visible": {
        outline: "none",
      },
      "& .input:-webkit-autofill": {
        background: "transparent !important",
        color: "#838383 !important",
      }
    },
    odin2: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      background: "#F7F7F7",
      color: "#838383",
      alignItems: "center",
      paddingRight: "18px",
      borderRadius: "8px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
      "& .icon": {
        width: "13px",
        height: "13px",
      },
      "& .line": {
        width: "1px",
        height: "16px",
        background: "#D4D4D4",
        marginRight: "15px",
      },
      "& .input": {
        fontFamily: "Lexend",
        background: "transparent",
        border: "none",
        fontStyle: "normal",
        color: "#838383",
        fontWeight: "500",
        fontSize: "12px",
        padding: "17px 19px 17px 15px",
        width: "100%",
        lineHeight: "15px",
      },
      "& .input:focus-visible": {
        outline: "none",
      },
      "& .input:-webkit-autofill": {
        background: "transparent !important",
        color: "#838383 !important",
      }
    },
  }));
  return styles;
};

const InputMessageComponent = () => {
  const styles = makeStyles((theme) => ({

  }));
  return styles;
};

const ButtonComponent = () => {
  const styles = makeStyles((theme) => ({
    enabled: {
      width: "100%",
      color: "#FFF",
      fontFamily: "Lexend",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "13px",
      lineHeight: "16px",
      padding: "20px 0",
      background: "#7FDD99",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
      borderRadius: "19px",
      border: "none",
      cursor: "pointer",
      "&:hover": {
        background: "#6ACE86"
      }
    },
    secondary: {
      width: "100%",
      color: "#FFF",
      fontFamily: "Lexend",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "13px",
      lineHeight: "16px",
      padding: "10px 0",
      background: "#88E7A3",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
      borderRadius: "19px",
      border: "none",
      cursor: "pointer",
      "&:hover": {
        background: "#56DE7C"
      }
    },
    red: {
      width: "100%",
      color: "#FFF",
      fontFamily: "Lexend",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "13px",
      lineHeight: "16px",
      padding: "10px 0",
      background: "#DD7F7F",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
      borderRadius: "19px",
      border: "none",
      cursor: "pointer",
      "&:hover": {
        background: "#DD7F7F"
      }
    }
  }));
  return styles;
};

export {
  MessageStyles,
  ModalStyles,
  IconInputComponent,
  InputMessageComponent,
  ButtonComponent,
};
