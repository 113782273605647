import React, { Component } from "react"
import Chart from "react-apexcharts"
import moment from "moment"

const ChartExport = ({ datos, year }) => {

    const getVentas = _mes => {
        let ventas = 0
        if (datos?.length > 0) {
            let datosFiltrados = datos?.filter(dato => {
                return (moment(dato?.creado).format("YYYY") == year && moment(dato?.creado).format("M") == moment().month(_mes).format("M"))
            })
            ventas = datosFiltrados?.length
        }
        return ventas
    }
    const options = {
        chart: {
            id: "basic-bar"
        },
        xaxis: {
            categories: [
                moment().month(0).format("MMMM"),
                moment().month(1).format("MMMM"),
                moment().month(2).format("MMMM"),
                moment().month(3).format("MMMM"),
                moment().month(4).format("MMMM"),
                moment().month(5).format("MMMM"),
                moment().month(6).format("MMMM"),
                moment().month(7).format("MMMM"),
                moment().month(8).format("MMMM"),
                moment().month(9).format("MMMM"),
                moment().month(10).format("MMMM"),
                moment().month(11).format("MMMM"),
            ]
        }
    }

    const series = [
        {
            name: "series-1",
            data: [
                getVentas(0),
                getVentas(1),
                getVentas(2),
                getVentas(3),
                getVentas(4),
                getVentas(5),
                getVentas(6),
                getVentas(7),
                getVentas(8),
                getVentas(9),
                getVentas(11),
                getVentas(12),
            ]
        }
    ]

    return (
        <div className="app" style={{ width: "calc(100% - 10px)" }}>
            <div className="row">
                <div className="mixed-chart">
                    <Chart
                        options={options}
                        series={series}
                        type="area"
                        width="100%"
                    />
                </div>
            </div>
        </div>
    )
}

export default ChartExport