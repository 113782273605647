import { CircularProgress, IconButton } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { HiOutlinePhotograph } from 'react-icons/hi'
import { BiArrowBack } from 'react-icons/bi'
import { BiRefresh } from 'react-icons/bi'
import { MdMotionPhotosOn } from 'react-icons/md'
import { MdDone } from 'react-icons/md'

const Camara = ({ photo, setPhoto, toShow, setToShow }) => {
    const [opened, setOpened] = useState(false)
    const [videoSize, setVideoSize] = useState({ x: 0, y: 0 })
    const [loading, setLoading] = useState(false)
    const videoRef = useRef(null)
    const photoRef = useRef(null)
    const [hasPhoto, setHasPhoto] = useState(false)

    const handleStream = (_stream) => {
        videoRef.current.srcObject = _stream
        _stream == null ? videoRef.current.pause() : videoRef.current.play()
    }


    const getVideo = async () => {
        setOpened(true)
        setLoading(true)
        let _stream = await navigator.mediaDevices.getUserMedia({
            video: {
                facingMode: "environment"
            },
        })
        window.localStream = _stream
        _stream = await navigator.mediaDevices.getUserMedia({
            video: {
                facingMode: "environment"
            },
        })
        window.localStream = _stream
        setVideoSize({ x: _stream.getVideoTracks()[0].getSettings().width, y: _stream.getVideoTracks()[0].getSettings().height })
        handleStream(_stream)
        setLoading(false)
    }

    const takePhoto = () => {
        setHasPhoto(true)
        let video = videoRef.current
        let photo = photoRef.current
        video.width = window.localStream.getVideoTracks()[0].getSettings().width
        video.height = window.localStream.getVideoTracks()[0].getSettings().height
        photo.width = window.localStream.getVideoTracks()[0].getSettings().width
        photo.height = window.localStream.getVideoTracks()[0].getSettings().height
        let ctx = photo.getContext("2d")
        ctx.drawImage(video, 0, 0, videoSize.x, videoSize.y)
        stopVideo(videoRef)
    }

    const stopVideo = (video) => {
        video.current.srcObject.getVideoTracks().forEach((track) => {
            track.stop()
            video.current.srcObject.removeTrack(track)
        })
    }

    useEffect(() => {
        return () => {
            window.localStream.getVideoTracks().forEach((track) => track.stop())
        }
    }, [])

    return (
        <>
            {
                opened &&
                <div style={{
                    position: "fixed",
                    inset: 0,
                    background: "black",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    zIndex: 2,
                }}>
                    {
                        loading &&
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            position: "absolute"
                        }}>
                            <CircularProgress />
                        </div>
                    }
                    <canvas style={{
                        display: hasPhoto ? "block" : "none",
                        position: "fixed",
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                    }} ref={photoRef} />
                    <video style={{ width: "100%", height: "100%" }} ref={videoRef} controls={false} webkit-playsinline="true" playsinline="true" />
                    <div style={{
                        position: "absolute",
                        bottom: "30px",
                        left: "30px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <IconButton style={{
                            background: "white",
                            height: "100px",
                            width: "100px",
                        }}
                            onClick={() => {
                                stopVideo(videoRef)
                                setOpened(false)
                            }}>
                            <BiArrowBack />
                        </IconButton>
                    </div>
                    <div style={{
                        position: "absolute",
                        bottom: "30px",
                        right: "30px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <IconButton style={{
                            background: "white",
                            height: "100px",
                            width: "100px",
                        }}
                            onClick={() => {
                                setHasPhoto(false)
                                getVideo()
                            }}>
                            <BiRefresh />
                        </IconButton>
                    </div>
                    <div style={{
                        position: "absolute",
                        bottom: "30px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <IconButton style={{
                            background: "white",
                            height: "100px",
                            width: "100px",
                        }}
                            onClick={() => {
                                if (hasPhoto) {
                                    setPhoto(photoRef?.current)
                                    setToShow(photoRef?.current?.toDataURL())
                                    stopVideo(videoRef)
                                    setOpened(false)
                                } else {
                                    takePhoto()
                                }
                            }}>
                            {
                                hasPhoto
                                    ?
                                    <MdDone />
                                    :
                                    <MdMotionPhotosOn />
                            }
                        </IconButton>
                    </div>
                </div>
            }
            <div>
                {
                    toShow
                        ?
                        <img style={{ width: "100%", maxHeight: "300px", objectFit: "cover" }} onClick={() => {
                            setPhoto(null)
                            setHasPhoto(false)
                            getVideo()
                        }} src={toShow} />
                        :
                        <>
                            <p style={{
                                color: "#838383",
                                fontSize: "12px",
                                fontFamily: "Lexend",
                                fontWeight: 500,
                            }}>Fotografía del artículo</p>
                            <div onClick={() => { getVideo() }} style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "2px dashed #A9A9A9",
                                padding: "50px 0",
                                cursor: "pointer",
                            }}>
                                <HiOutlinePhotograph style={{
                                    color: "#838383",
                                    fontSize: "30px",
                                    fontFamily: "Lexend",
                                    fontWeight: 500,
                                }}
                                />
                                <p style={{
                                    color: "#838383",
                                    fontSize: "12px",
                                    fontFamily: "Lexend",
                                    fontWeight: 500,
                                    margin: 0
                                }}>Seleccionar foto</p>
                            </div>
                        </>
                }
            </div>
        </>
    )
}

export default Camara