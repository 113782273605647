
import Articulos from "../views/articulos"
import ArticulosDetail from "../views/articulos/detail.js"
import Pedidos from "../views/pedidos"
import PedidosDetail from "../views/pedidos/detail.js"
import Estadisticas from "../views/estadisticas"

const enlaces = [
    {
        nombre: "Articulos",
        tipo: "NavLink",
        ruta: "/articulos",
        permiso: "articulos",
        vista: Articulos,
        detail: ArticulosDetail,
    },
    {
        nombre: "Pedidos",
        tipo: "NavLink",
        ruta: "/pedidos",
        permiso: "pedidos",
        vista: Pedidos,
        detail: PedidosDetail,
    },
    {
        nombre: "Estadisticas",
        tipo: "NavLink",
        ruta: "/estadisticas",
        permiso: "estadisticas",
        vista: Estadisticas,
    },
];

export default enlaces;
