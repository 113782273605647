import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Chart from '../../components/Chart'
import { CircularProgress, Grid } from '@mui/material'
import servo from '../../services/servo'
import FullScreenLoader from "../../components/FullScreenLoader";
import { useAuth } from "../../hooks/useAuth"
import Articulo from '../../components/Articulo'
import { useHistory } from 'react-router-dom';
import { BsArrowRightShort } from 'react-icons/bs'
import { BsArrowLeftShort } from 'react-icons/bs'
import { AiOutlineSearch } from 'react-icons/ai'
import IconInput from '../../components/IconInput'
const Home = () => {
  const { loginLoading } = useAuth();
  const [loading, setLoading] = useState(false)
  const [datos, setDatos] = useState([])
  const history = useHistory()
  const [datosA, setDatosA] = useState([])
  const [page, setPage] = useState(1)
  const [besqueda, setBusqueda] = useState("")
  const filtro = (_datos) => {
    let datosFiltrados = _datos.filter(dato => dato?.nombre?.toLowerCase()?.includes(besqueda?.toLowerCase()))
    return datosFiltrados
  }
  const getDatos = async (_fecha = false) => {
    setLoading(true)
    let res = await servo.get("pedidos?eliminado=1&completado=1&expand=articulos")
    setDatos(res?.cuerpo?.resultado)
    let res2 = await servo.get("articulos?expand=archivo")
    setDatosA(res2?.cuerpo?.resultado)
    setLoading(false)
  }
  useEffect(() => {
    getDatos()
  }, [])

  if (loginLoading) return <FullScreenLoader />;

  return (
    <>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} sm={6} md={4} lg={3} style={{ flexDirection: "column" }}>
          {
            !loading
              ?
              <>
                <div>
                  <p style={{
                    color: "#7B7B7B",
                    fontFamily: "Lexend",
                    fontStyle: "normal",
                    fontSize: "24px",
                    fontWeight: "700",
                    lineHeight: "30px",
                    padding: "55px 0 0 15px",
                    margin: 0,
                  }}>Resumen</p>
                </div>
                <div style={{
                  padding: "15px 15px 0 15px",
                  display: "flex",
                  flexDirection: "row",
                }}>
                  <Chart datos={datos} year={moment(new Date()).format("YYYY")} />
                </div>
                <div style={{
                  padding: "15px 35px 0 15px",
                  display: "flex",
                  flexDirection: "row",
                }}>
                  <IconInput type={"text"} value={besqueda} onChange={e => { setBusqueda(e.target.value) }} placeholder={"Buscar"} Icon={AiOutlineSearch} back />
                </div>
                <div style={{
                  padding: "0 15px",
                  display: "flex",
                  flexDirection: "column",
                }}>
                  <div >
                    <p style={{ fontFamily: "Lexend", fontStyle: "normal", fontWeight: 500, fontSize: "12px", lineHeight: "15px", padding: "10px 0", color: "#838383", margin: 0 }}>Resultados ({datosA && filtro(datosA)?.length})</p>
                    <Grid container spacing={2}>
                      {
                        datosA && datosA?.length > 0 && filtro(datosA).slice(page > 1 ? ((page - 1) * 10) : (page - 1), (page * 10)).map(dato => {
                          return (
                            <Grid item xs={6}>
                              <Articulo dato={dato} onClick={() => {
                                history.push("/articulos/editar/" + dato?.idArticulo)
                              }} />
                            </Grid>
                          )
                        })
                      }
                    </Grid>
                    <div style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "25px"
                    }}>
                      <button
                        style={{
                          border: "none",
                          width: "30px",
                          height: "30px",
                          background: "#F6F6F6",
                          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
                          color: "#7B7B7B",
                        }}
                        onClick={() => {
                          if (page > 1) {
                            let num = page
                            num -= 1
                            setPage(num)
                          }
                        }}><BsArrowLeftShort /></button>
                      <p style={{
                        padding: "0 6px",
                        fontSize: "14px",
                        fontFamily: "Lexend",
                        color: page == 1 ? "white" : "#7B7B7B",
                      }}>{page - 1}</p>
                      <p style={{
                        padding: "0 6px",
                        fontSize: "14px",
                        fontFamily: "Lexend",
                        color: "#7FDD99",
                      }}>{page}</p>
                      <p style={{
                        padding: "0 6px",
                        fontSize: "14px",
                        fontFamily: "Lexend",
                        color: Math.ceil((filtro(datos)?.length / 10)) <= page ? "white" : "#7B7B7B",
                      }}>{page + 1}</p>
                      <button
                        style={{
                          border: "none",
                          width: "30px",
                          height: "30px",
                          background: "#F6F6F6",
                          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
                          color: "#7B7B7B",
                        }}
                        onClick={() => {
                          if (!(Math.ceil((filtro(datos)?.length / 10)) <= page)) {
                            let num = page
                            num += 1
                            setPage(num)
                          }
                        }}><BsArrowRightShort /></button>
                    </div>
                  </div>
                </div>
              </>
              :
              <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", padding: "25px 0" }}>
                <CircularProgress />
              </div>
          }
        </Grid>
      </Grid>
    </>
  )
};

export default Home;
