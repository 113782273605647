import React from "react"
import IconInput from "../components/IconInput"
import PersonIcon from '@mui/icons-material/Person'
import InputMessage from '../components/InputMessage'
import Button from "../components/Button"
import LockIcon from '@mui/icons-material/Lock';
const Login = ({
  onClick,
  correo,
  setCorreo,
  clave,
  setClave,
  errorFirebase,
  obtenerErrorFirebase,
}) => {
  const logo = "https://firebasestorage.googleapis.com/v0/b/react-ventas.appspot.com/o/Sistema%2Flogo.png?alt=media&token=dd305b7a-e12e-4107-905b-c53b86110d01&_gl=1*3d3c4y*_ga*MTgzNzMzOTM1Ny4xNjg2NjgzMDY4*_ga_CW55HF8NVT*MTY4NjY5NDM0Ny40LjEuMTY4NjY5NDM2NC4wLjAuMA.."
  return (
    <div style={{ display: "flex", flexDirection: "column", }}>
      <div style={{ width: "100%", marginBottom: "15px", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <img style={{ width: "100px", height: "100px" }} src={logo} />
        <p style={{
          fontFamily: "Lexend",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "20px",
          lineHeight: "25px",
          color: "#7B7B7B",
          margin: "20px 0",
        }}>Iniciar sesión</p>
      </div>
      <div style={{ width: "calc(100% - 19px)", marginBottom: "15px" }}>
        <IconInput
          front
          type={"mail"}
          valor={correo.toUpperCase()}
          placeholder="Correo"
          Icon={PersonIcon}
          onChange={e => {
            setCorreo(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              onClick(e)
            }
          }}
        />
      </div>
      <div style={{ width: "calc(100% - 19px)", marginBottom: "15px" }}>
        <IconInput
          front
          type={"password"}
          valor={clave}
          placeholder="Contraseña"
          Icon={LockIcon}
          onChange={e => {
            setClave(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              onClick(e)
            }
          }}
        />
      </div>
      <Button
        onClick={onClick}
        label="Entrar"
      />
      {
        errorFirebase !== null && (
          <InputMessage message={obtenerErrorFirebase(errorFirebase?.message)} />
        )
      }
    </div >
  )
}

export default Login
