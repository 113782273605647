import React from "react";
import { ButtonComponent } from "../utils/styles";

let BC = ButtonComponent();
const Button = ({
  disabled = false,
  label = "",
  onClick,
  secondary,
  style = {},
  red = false,
}) => {
  let ButtonComponent = BC();
  return (
    <button
      style={style}
      className={
        disabled
          ? ButtonComponent.disabled
          :
          secondary
            ?
            ButtonComponent.secondary
            :
            red
              ?
              ButtonComponent.red
              :
              ButtonComponent.enabled
      }
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default Button;
