import { CircularProgress, Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'
import HomeComponent from '../../components/HomeComponent'
import moment from 'moment';
import Dia from '../../components/Dia'
import Year from '../../components/Year'
import Mes from '../../components/Mes'
import IconInput from '../../components/IconInput'
import { AiOutlineSearch } from 'react-icons/ai'
import { BsArrowRightShort } from 'react-icons/bs'
import { BsArrowLeftShort } from 'react-icons/bs'
import Servo from '../../services/servo'
import Articulo from '../../components/Articulo'
import { useHistory } from 'react-router-dom';

const Articulos = () => {
    const [besqueda, setBusqueda] = useState("")
    const [fecha, setFecha] = useState({
        dia: "",
        mes: "",
        year: moment(new Date()).format("YYYY"),
    })
    const history = useHistory()
    const [datos, setDatos] = useState([])
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const getDatos = async (_fecha = false) => {
        setLoading(true)
        let extra = _fecha ? ("?fecha=" + moment(_fecha?.year + "-" + _fecha?.mes + "-" + _fecha?.dia).format("YYYY-MM-DD")) + "&expand=archivo" : "?expand=archivo"
        let res = await Servo.get("articulos" + extra)
        setDatos(res?.cuerpo?.resultado)
        setLoading(false)
    }
    const filtro = (_datos) => {
        let datosFiltrados = _datos.filter(dato => dato?.nombre?.toLowerCase()?.includes(besqueda?.toLowerCase()))
        return datosFiltrados
    }
    useEffect(() => {
        getDatos()
    }, [])

    useEffect(() => {
        if (fecha?.dia != "" && fecha?.mes != "" && fecha?.year != "") {
            getDatos(fecha)
        }
    }, [fecha])
    return (
        <>
            <Grid container justifyContent={"center"}>
                <Grid item xs={12} sm={6} md={4} lg={3} style={{ flexDirection: "column" }}>
                    <div style={{
                        padding: "55px 0 15px 15px"
                    }}>
                        <HomeComponent />
                    </div>
                    <div>
                        <p style={{
                            color: "#7B7B7B",
                            fontFamily: "Lexend",
                            fontStyle: "normal",
                            fontSize: "24px",
                            fontWeight: "700",
                            lineHeight: "30px",
                            padding: "0 0 0 15px",
                            margin: 0,
                        }}>Artículos</p>
                    </div>
                    <div style={{
                        padding: "15px 35px 0 15px",
                        display: "flex",
                        flexDirection: "row",
                    }}>
                        <IconInput type={"text"} value={besqueda} onChange={e => { setBusqueda(e.target.value) }} placeholder={"Buscar"} Icon={AiOutlineSearch} back />
                    </div>
                    <div style={{
                        padding: "15px 15px 0 15px",
                        display: "flex",
                        flexDirection: "row",
                    }}>
                        <div style={{
                            width: "100%",
                            marginRight: "15px"
                        }}>
                            <Dia value={fecha?.dia} date={"2023/06"} onChange={e => { setFecha({ ...fecha, dia: e.target.value }) }} />
                        </div>
                        <div style={{
                            width: "100%",
                            marginRight: "15px"
                        }}>
                            <Mes value={fecha?.mes} onChange={e => { setFecha({ ...fecha, mes: e.target.value }) }} />
                        </div>
                        <div style={{
                            width: "100%",
                        }}>
                            <Year value={fecha?.year} date={"2021/01/01"} onChange={e => { setFecha({ ...fecha, year: e.target.value }) }} />
                        </div>
                    </div>
                    <div style={{
                        padding: "0 15px",
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        {
                            !loading
                                ?
                                <div >
                                    <p style={{ fontFamily: "Lexend", fontStyle: "normal", fontWeight: 500, fontSize: "12px", lineHeight: "15px", padding: "10px 0", color: "#838383", margin: 0 }}>Resultados ({datos && filtro(datos)?.length})</p>
                                    <Grid container spacing={2}>
                                        {
                                            datos && datos?.length > 0 && filtro(datos).slice(page > 1 ? ((page - 1) * 10) : (page - 1), (page * 10)).map(dato => {
                                                return (
                                                    <Grid item xs={6}>
                                                        <Articulo dato={dato} onClick={() => {
                                                            history.push("/articulos/editar/" + dato?.idArticulo)
                                                        }} />
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginBottom: "25px"
                                    }}>
                                        <button
                                            style={{
                                                border: "none",
                                                width: "30px",
                                                height: "30px",
                                                background: "#F6F6F6",
                                                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
                                                color: "#7B7B7B",
                                            }}
                                            onClick={() => {
                                                if (page > 1) {
                                                    let num = page
                                                    num -= 1
                                                    setPage(num)
                                                }
                                            }}><BsArrowLeftShort /></button>
                                        <p style={{
                                            padding: "0 6px",
                                            fontSize: "14px",
                                            fontFamily: "Lexend",
                                            color: page == 1 ? "white" : "#7B7B7B",
                                        }}>{page - 1}</p>
                                        <p style={{
                                            padding: "0 6px",
                                            fontSize: "14px",
                                            fontFamily: "Lexend",
                                            color: "#7FDD99",
                                        }}>{page}</p>
                                        <p style={{
                                            padding: "0 6px",
                                            fontSize: "14px",
                                            fontFamily: "Lexend",
                                            color: Math.ceil((filtro(datos)?.length / 10)) <= page ? "white" : "#7B7B7B",
                                        }}>{page + 1}</p>
                                        <button
                                            style={{
                                                border: "none",
                                                width: "30px",
                                                height: "30px",
                                                background: "#F6F6F6",
                                                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
                                                color: "#7B7B7B",
                                            }}
                                            onClick={() => {
                                                if (!(Math.ceil((filtro(datos)?.length / 10)) <= page)) {
                                                    let num = page
                                                    num += 1
                                                    setPage(num)
                                                }
                                            }}><BsArrowRightShort /></button>
                                    </div>
                                </div>
                                :
                                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", padding: "25px 0" }}>
                                    <CircularProgress />
                                </div>
                        }
                    </div>
                </Grid>
            </Grid >
        </>
    )
}

export default Articulos