import React from "react"
import moment from 'moment'

const Dia = ({ date, value, onChange }) => {
    const days = moment(date, "YYYY-MM").daysInMonth();
    const getMenuItems = () => {
        let menuItems = [];
        for (let index = 1; index < days + 1; index++) {
            menuItems.push(
                <option key={index} value={index < 10 ? "0" + index : index}>{index < 10 ? "0" + index : index}</option>
            );
        }
        return menuItems
    }
    return (
        <select name="select"
            style={{
                fontFamily: 'Lexend',
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "12px",
                height: "50px",
                width: "100%",
                "-webkit-appearance": "none",
                "-webkit-box-shadow": "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
                lineHeight: "15px",
                textAlign: "center",
                color: "#838383",
                padding: "10px",
                border: "none",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
                borderRadius: "8px",
                background: "#F7F7F7",
                outline: "none",
                textAlign: "left",
            }}
            value={value}
            onChange={onChange}
        >
            <option value={""} disabled selected hidden>Día</option>
            {getMenuItems()}
        </select >
    )
}

export default Dia