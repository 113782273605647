import React from "react"
import moment from 'moment'

const Year = ({ date, value, onChange }) => {
    const years = moment(date).diff(new Date(), "years", false)
    const getMenuItems = () => {
        let menuItems = [];
        moment(new Date()).subtract(1, "year").year()
        for (let index = 0; index < Math.abs(years); index++) {
            menuItems.push(
                <option key={index} value={moment(new Date()).subtract(index, "year").year()}>{moment(new Date()).subtract(index, "year").year()}</option>
            );
        }
        return menuItems
    }
    return (
        <select name="select"
            style={{
                fontFamily: 'Lexend',
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "12px",
                height: "50px",
                width: "100%",
                "-webkit-appearance": "none",
                "-webkit-box-shadow": "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
                lineHeight: "15px",
                textAlign: "center",
                color: "#838383",
                width: "100%",
                padding: "10px",
                border: "none",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
                borderRadius: "8px",
                background: "#F7F7F7",
                outline: "none",
                textAlign: "left",
            }}
            value={value}
            onChange={onChange}
        >
            <option value={""} disabled selected hidden>Year</option>
            {getMenuItems()}
        </select >
    )
}

export default Year