import React from "react";
import { InputMessageComponent } from "../utils/styles";

let ISC = InputMessageComponent();
const InputMessage = ({ style, message, ...props }) => {
  let InputMessageComponent = ISC();
  return (
    <div style={style} className={InputMessageComponent.inputMessage}>
      <p>{message}</p>
    </div>
  );
};

export default InputMessage;
