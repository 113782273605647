import { IconButton } from "@mui/material"
import React from "react"
import { AiFillHome } from 'react-icons/ai'
import { useHistory } from "react-router-dom"

const HomeComponent = () => {
    const history = useHistory()
    return (
        <IconButton onClick={() => history.push("inicio")}><AiFillHome style={{ color: "#7FDD99", fontSize: "35px" }} /></IconButton >
    )
}

export default HomeComponent