
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useModal } from "../hooks/useModal";
import { ModalStyles } from "../utils/styles";

const MS = ModalStyles();
const Modal = () => {
    const { open, setOpen, containerModal } = useModal();
    const ModalStyles = MS();
    const isClickOut = e => {
        e.target.id == "odin" && setOpen(false)
    }

    return (
        <div id={"odin"} className={ModalStyles?.odin} onClick={e => isClickOut(e)} style={{ display: open ? "block" : "none", }}>
            <div className="thor">
                <div className="magni">
                    <IconButton onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </div>
                {containerModal}
            </div>
        </div>
    )
}

export default Modal