import React from 'react'

const Pedido = ({ dato, onClick = () => { } }) => {
    return (
        <div style={{
            background: !dato?.eliminado ? !dato?.fechaEntrega ? "#F6C882" : "#7FDD99" : "#DD7F7F",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px",
            borderRadius: "7px",
            alignItems: "center",
            marginBottom: "15px",
            cursor: "pointer"
        }} onClick={dato?.eliminado ? () => { } : onClick}>
            <p style={{
                margin: "0",
                color: "F6F6F6",
                fontFamily: "Lexend",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "18px",
            }}>#{dato?.idPedido} {dato?.solicitante}</p>
            <button style={{
                background: "FFFFFF",
                borderRadius: "12px",
                border: "none",
                padding: "4 15px",
                fontFamily: "Lexend",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "10px",
                lineHeight: "12px",
                textAlign: "center",
                color: "#A9A9A9"
            }}>Detalles</button>
        </div>
    )
}

export default Pedido