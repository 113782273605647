import React, { useState, useEffect } from 'react'
import { CircularProgress, Grid } from '@mui/material'
import moment from 'moment'
import Servo from '../../services/servo'
import Input from '../../components/Input'
import IconInput from '../../components/IconInput'
import Button from '../../components/Button'
import Camara from '../../components/Camara'
import TextArea from '../../components/TextArea'
import { MdAttachMoney } from 'react-icons/md'
import FirebaseStorage from '../../services/firebase'
import Swal from "sweetalert2"
import { useAlert } from '../../hooks/useAlert'
import { useMessages } from "../../hooks/useMessages"
import { useHistory } from "react-router-dom"

const ArticulosDetail = ({ match }) => {
    const [articulo, setArticulo] = useState({

    })
    const [photo, setPhoto] = useState(null)
    const id = match?.params?.id
    const history = useHistory()
    const { showAlert } = useAlert()
    const { setMessages } = useMessages()
    const isEditing = !!match?.params?.id
    const [toShow, setToShow] = useState("")
    const [loading, setLoading] = useState(false)
    const getDatos = async () => {
        setLoading(true)
        let res = await Servo.get("articulos?idArticulo=" + id + "&expand=archivo")
        setArticulo({
            ...res?.cuerpo?.resultado[0],
            fechaCompra: moment(res?.cuerpo?.resultado[0]?.fechaCompra).format("YYYY-MM-DD"),
            fechaImportacion: moment(res?.cuerpo?.resultado[0]?.fechaImportacion).format("YYYY-MM-DD")
        })
        setToShow(res?.cuerpo?.resultado[0]?.archivo?.url)
        setLoading(false)
    }
    const borrarArticulo = async () => {
        setLoading(true)
        let res = await Servo.post("articulos/borrar", { idArticulo: id }, "articulos", true, history, true)

        if (res?.cuerpo) {
            setMessages(res?.cuerpo)
        }
        setLoading(false)
    }
    const subirArticulo = async () => {
        setLoading(true)
        let body = { ...articulo }
        if (photo) {
            const blob = await new Promise((resolve) => photo.toBlob(resolve))
            const folder = "Archivos"
            const ranm = Math.floor(Math.random() * 1000)
            const nombreFirebase =
                "Articulo_" +
                articulo?.nombre +
                "_" +
                moment(new Date()).format("DD_MM_YYYY_HH_mm_ss") +
                "_" +
                ranm +
                "." +
                blob?.type.split("/")[1]

            let url = await FirebaseStorage.upload(folder + "/" + nombreFirebase, blob)

            body = {
                ...body,
                archivo: {
                    url,
                    tipo: blob?.type,
                    folder,
                    nombre: nombreFirebase,
                    nombreFirebase,
                }
            }
        }

        let res = await Servo.post("articulos/guardar", body, "articulos", true, history, true)

        if (res?.cuerpo) {
            setMessages(res?.cuerpo)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (isEditing) {
            getDatos()
        }
    }, [])

    return (
        <>
            <div style={{
                padding: "55px 0 15px 15px"
            }}>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12} sm={6} md={4} lg={3} style={{ flexDirection: "column" }}>
                        <div>
                            <p style={{
                                color: "#7B7B7B",
                                fontFamily: "Lexend",
                                fontStyle: "normal",
                                fontSize: "24px",
                                fontWeight: "700",
                                lineHeight: "30px",
                                padding: "0 0 0 15px",
                                margin: 0,
                            }}>{isEditing ? "Editar" : "Nuevo"} Artículo</p>
                        </div>
                        <div style={{
                            padding: "15px 15px 0 15px",
                        }}>
                            <Input label={"Nombre del producto"} placeholder={"Nombre"} valor={articulo?.nombre} onChange={e => { setArticulo({ ...articulo, nombre: e.target.value }) }} />
                        </div>
                        <div style={{
                            padding: "15px 35px 0px 15px",
                            display: "flex",
                            flexDirection: "row",
                        }}>
                            <IconInput label={"Precio"} placeholder={""} type='number' valor={articulo?.precio} front Icon={MdAttachMoney} onChange={e => {
                                setArticulo({ ...articulo, precio: e.target.value })
                            }} />
                        </div>
                        <div style={{
                            padding: "15px 35px 0px 15px",
                            display: "flex",
                            flexDirection: "row",
                        }}>
                            <IconInput label={"Precio más ganancia"} placeholder={""} type='number' valor={articulo?.precioPublico} front Icon={MdAttachMoney} onChange={e => {
                                setArticulo({ ...articulo, precioPublico: e.target.value })
                            }} />
                        </div>
                        <div style={{
                            padding: "15px 15px 0 15px",
                        }}>
                            <Input label={"Lugar de compra"} placeholder={"Lugar"} valor={articulo?.lugarCompra} onChange={e => { setArticulo({ ...articulo, lugarCompra: e.target.value }) }} />
                        </div>
                        <div style={{
                            padding: "15px 15px 0 15px",
                        }}>
                            <Input
                                label={"Fecha de Compra"}
                                placeholder={""}
                                type='date'
                                valor={articulo?.fechaCompra}
                                onChange={e => {
                                    setArticulo({ ...articulo, fechaCompra: moment(e.target.value).format("YYYY-MM-DD") })
                                }}
                            />
                        </div>
                        <div style={{
                            padding: "15px 15px 0 15px",
                        }}>
                            <Input
                                label={"Fecha de pase a México"}
                                placeholder={""}
                                type='date'
                                valor={articulo?.fechaImportacion}
                                onChange={e => {
                                    setArticulo({ ...articulo, fechaImportacion: moment(e.target.value).format("YYYY-MM-DD") })
                                }}
                            />
                        </div>
                        <div style={{
                            padding: "15px 15px 0 15px",
                        }}>
                            <Camara photo={photo} setPhoto={setPhoto} toShow={toShow} setToShow={setToShow} />
                        </div>
                        <div style={{
                            padding: "15px 15px 0 15px",
                        }}>
                            <TextArea rows={5} valor={articulo?.notas} label={"Notas"} placeholder={""} onChange={e => { setArticulo({ ...articulo, notas: e.target.value }) }} />
                        </div>
                        <div style={{
                            padding: "15px 15px 0 15px",
                            display: "flex",
                            flexDirection: "row",

                        }}>
                            {
                                !loading
                                    ?
                                    <>
                                        <Button red label={"Cancelar"} onClick={() => {
                                            history.push("/articulos")
                                        }} />
                                        <div style={{ width: "20px" }}></div>
                                        <Button label={"Guardar"} onClick={() => { subirArticulo() }} />
                                    </>
                                    :
                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center"
                                    }}>
                                        <CircularProgress />
                                    </div>
                            }
                        </div>
                        {
                            !loading &&
                            <>
                                {
                                    isEditing &&
                                    <div style={{
                                        padding: "15px 15px 30px 15px",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <Button red label={"Borrar Artículo"} onClick={() => {
                                            Swal.fire({
                                                title: '¿Deseas borrar el artículo?',
                                                showCancelButton: true,
                                                confirmButtonText: 'Si',
                                                confirmButtonColor: '#7FDD99',
                                                cancelButtonColor: '#FFF',
                                                cancelButtonText: '<p style="color:#7FDD99;margin:5px 0;">No</p>'
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    borrarArticulo()
                                                }
                                            })
                                        }} />
                                        <div style={{ width: "1px", height: "56px" }}></div>
                                    </div>
                                }
                            </>
                        }

                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default ArticulosDetail