import React from "react";
import { IconInputComponent } from "../utils/styles";
const IIC = IconInputComponent();
const IconInput = ({
    disabled = "false",
    Icon,
    placeholder,
    valor,
    label,
    type,
    onChange = () => { },
    onKeyDown,
    front = false,
    back = false
}) => {
    const IconInputComponent = IIC();
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            width:"100%"
        }}>
            <p style={{
                margin: "0",
                fontSize: "12px",
                fontFamily: "Lexend",
                fontWeight: 400,
                color: "#838383",
                marginBottom: "5px",
            }}>{label}</p>
            <div className={front ? IconInputComponent.odin : IconInputComponent.odin2}>
                {
                    front && (
                        <>
                            <div>
                                <Icon className={"icon"} />
                            </div>
                            <div className="line"></div>
                        </>
                    )
                }
                <input
                    className={"input"}
                    placeholder={placeholder}
                    value={valor}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    type={type}
                />
                {
                    back && (
                        <>
                            <div className="line"></div>
                            <div>
                                <Icon className={"iconBack"} />
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default IconInput;
