import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Year from '../../components/Year'
import Mes from '../../components/Mes'
import Chart from '../../components/Chart'
import HomeComponent from '../../components/HomeComponent'
import { CircularProgress, Grid } from '@mui/material'
import servo from '../../services/servo'

const Estadisticas = () => {
    const [fecha, setFecha] = useState({
        mes: "",
        year: moment(new Date()).format("YYYY"),
    })
    const [loading, setLoading] = useState(false)
    const [datos, setDatos] = useState([])
    const [datosF, setDatosF] = useState([])
    const filtro = _datos => {
        let datosFiltrados = []
        console.log("datos", _datos)
        if (_datos?.length > 0) {
            datosFiltrados = _datos?.filter(dato => {
                return dato?.creado?.split("-")[0] == fecha?.year
            })
            datosFiltrados = datosFiltrados?.filter(dato => {
                return parseInt(fecha?.mes) === parseInt(dato?.creado?.split("-")[1])
            })
            console.log("datosFiltrados", datosFiltrados)
        }
        return datosFiltrados
    }
    const getDatos = async (_fecha = false) => {
        setLoading(true)
        let res = await servo.get("pedidos?eliminado=1&completado=1&expand=articulos")
        setDatos(res?.cuerpo?.resultado)
        setLoading(false)
    }
    useEffect(() => {
        getDatos()
    }, [])

    useEffect(() => {
        setDatosF(filtro(datos))
    }, [datos])

    useEffect(() => {
        setDatosF(filtro(datos))
    }, [fecha])

    const getGanancias = () => {
        let total = datosF.reduce((suma, act) => {
            if (act?.articulos?.length > 0) {
                return suma += act?.articulos.reduce((s, a) => {
                    return s += a?.precioPublico
                }, 0)
            } else {
                return suma
            }
        }, 0)
        return total
    }

    const getInvercion = () => {
        let total = datosF.reduce((suma, act) => {
            if (act?.articulos?.length > 0) {
                return suma += act?.articulos.reduce((s, a) => {
                    return s += a?.precio
                }, 0)
            } else {
                return suma
            }
        }, 0)
        return total
    }

    return (
        <>
            <Grid container justifyContent={"center"}>
                <Grid item xs={12} sm={6} md={4} lg={3} style={{ flexDirection: "column" }}>
                    {
                        !loading
                            ?
                            <>
                                <div style={{
                                    padding: "55px 0 15px 15px"
                                }}>
                                    <HomeComponent />
                                </div>
                                <div>
                                    <p style={{
                                        color: "#7B7B7B",
                                        fontFamily: "Lexend",
                                        fontStyle: "normal",
                                        fontSize: "24px",
                                        fontWeight: "700",
                                        lineHeight: "30px",
                                        padding: "0px 0 0 15px",
                                        margin: 0,
                                    }}>Estadisticas</p>
                                </div>
                                <div style={{
                                    padding: "15px 15px 0 15px",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <div style={{
                                        width: "100%",
                                        marginRight: "15px"
                                    }}>
                                        <Year value={fecha?.year} date={"2021/01/01"} onChange={e => { setFecha({ ...fecha, year: e.target.value }) }} />
                                    </div>
                                    <div style={{
                                        width: "100%",
                                    }}>
                                        <Mes value={fecha?.mes} onChange={e => { setFecha({ ...fecha, mes: e.target.value }) }} />
                                    </div>
                                </div>
                                <div style={{
                                    padding: "15px 15px 0 15px",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <Chart datos={datos} year={fecha?.year} />
                                </div>
                                {
                                    fecha?.mes != "" &&
                                    <>
                                        <div>
                                            <p style={{
                                                color: "#7B7B7B",
                                                fontFamily: "Lexend",
                                                fontStyle: "normal",
                                                fontSize: "24px",
                                                fontWeight: "700",
                                                lineHeight: "30px",
                                                padding: "55px 0 0 15px",
                                                margin: 0,
                                            }}>Ganancias</p>
                                        </div>
                                        <div style={{
                                            padding: "15px 15px 0 15px",
                                            display: "flex",
                                            flexDirection: "column",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center"
                                            }}>
                                                <p style={{
                                                    color: "#7B7B7B",
                                                    fontFamily: "Lexend",
                                                    fontStyle: "normal",
                                                    fontSize: "15px",
                                                    fontWeight: "500",
                                                    lineHeight: "30px",
                                                    margin: 0,
                                                }}>Ganancia:</p>
                                                <p style={{
                                                    color: "#7FDD99",
                                                    fontFamily: "Lexend",
                                                    fontStyle: "normal",
                                                    fontSize: "15px",
                                                    fontWeight: "500",
                                                    lineHeight: "30px",
                                                    margin: "0 10px",
                                                }}>${(getGanancias() - getInvercion()).toFixed(2)}</p>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center"
                                            }}>
                                                <p style={{
                                                    color: "#7B7B7B",
                                                    fontFamily: "Lexend",
                                                    fontStyle: "normal",
                                                    fontSize: "15px",
                                                    fontWeight: "500",
                                                    lineHeight: "30px",
                                                    margin: 0,
                                                }}>Inversión:</p>
                                                <p style={{
                                                    color: "#DD7F7F",
                                                    fontFamily: "Lexend",
                                                    fontStyle: "normal",
                                                    fontSize: "15px",
                                                    fontWeight: "500",
                                                    lineHeight: "30px",
                                                    margin: "0 10px",
                                                }}>${getInvercion().toFixed(2)}</p>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                            :
                            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", padding: "25px 0" }}>
                                <CircularProgress />
                            </div>
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default Estadisticas