import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import InsertChartIcon from '@mui/icons-material/InsertChart'
import AddIcon from '@mui/icons-material/Add'
import AssignmentIcon from '@mui/icons-material/Assignment'
import LogoutIcon from '@mui/icons-material/Logout'
import { IconButton } from '@mui/material'
import InventoryIcon from '@mui/icons-material/Inventory'
import Button from '../components/Button'
import Enlaces from '../const/enlaces'
import { useHistory } from "react-router-dom"
import { useAuth } from '../hooks/useAuth'


const Menu = () => {
    const isPc = useMediaQuery('(min-width:600px)')
    const logo = "https://firebasestorage.googleapis.com/v0/b/react-ventas.appspot.com/o/Sistema%2Flogo.png?alt=media&token=dd305b7a-e12e-4107-905b-c53b86110d01";
    const history = useHistory()
    const { signOut } = useAuth()
    return (
        <>
            {
                isPc
                    ?
                    <div style={{
                        position: "fixed",
                        left: 0,
                        right: 0,
                        top: 0,
                        background: "#7FDD99",
                        height: "60px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxShadow: "rgb(211, 211, 211) 0px 0px 7px 0px"
                    }}>
                        <div style={{ paddingLeft: "15px" }}><img style={{ width: "50px", cursor: "pointer" }} src={logo} onClick={e => {
                            history.push("inicio")
                        }} /></div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", padding: "0 5px", maxWidth: "1300px" }}>
                            {
                                <>
                                    <Button secondary label={"Nuevo Pedido"} style={{ margin: "0 10px" }} onClick={e => {
                                        history.push("/pedidos/nuevo")
                                    }} />
                                    {
                                        Enlaces.map(enlace => {
                                            return (
                                                <Button key={enlace.nombre} secondary style={{ margin: "0 10px" }} label={enlace.nombre} onClick={e => {
                                                    history.push(enlace.ruta)
                                                }} />
                                            )
                                        })
                                    }
                                </>
                            }
                        </div>
                        <div style={{ paddingRight: "15px" }}>
                            <IconButton onClick={signOut}><LogoutIcon style={{ color: "#F6F6F6", width: "26px", heigth: "26px", }} /></IconButton>
                        </div>
                    </div>
                    :
                    <div style={{
                        position: "fixed",
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex:2,
                        background: "#F6F6F6",
                        height: "70px",
                        display: "flex",
                        paddingBottom: "5px",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        boxShadow: "rgb(211 211 211) -2px 4px 10px 4px"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                            <IconButton onClick={e => {
                                history.push("/estadisticas")
                            }} ><InsertChartIcon style={{ color: "#A2A2A2", width: "26px", heigth: "26px", }} /></IconButton>
                            <p style={{
                                fontFamily: "Lexend",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "8px",
                                lineHeight: "10px",
                                color: "#838383",
                                margin: 0,
                            }}>Estadisticas</p>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                            <IconButton onClick={e => {
                                history.push("/articulos")
                            }} ><InventoryIcon style={{ color: "#A2A2A2" }} /></IconButton>
                            <p style={{
                                fontFamily: "Lexend",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "8px",
                                lineHeight: "10px",
                                color: "#838383",
                                margin: 0,
                            }}>Articulos</p>
                        </div>
                        <div style={{
                            position: "relative", display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <div style={{
                                position: "absolute",
                                width: "95px",
                                height: "95px",
                                top: "-50px",
                                boxShadow: "rgb(211, 211, 211) 0px 0px 10px 1px",
                                background: "#F6F6F6",
                                borderRadius: "100%",
                                zIndex: "-3",
                            }}>
                            </div>
                            <div style={{
                                position: "absolute",
                                width: "150px",
                                height: "80px",
                                top: "-10px",
                                background: "#F6F6F6",
                                zIndex: "-2",
                            }}></div>
                            <IconButton
                                onClick={e => {
                                    history.push("/pedidos/nuevo")
                                }}
                                style={{
                                    position: "absolute",
                                    width: "75px",
                                    height: "75px",
                                    background: "#7FDD99",
                                    top: "-40px",
                                }}><AddIcon style={{ color: "#F6F6F6", fontSize: "35px", }} /></IconButton>
                            <div style={{ width: "26px", height: "24px", padding: "8px" }}></div>
                            <p style={{
                                fontFamily: "Lexend",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "8px",
                                lineHeight: "10px",
                                color: "#838383",
                                margin: 0,
                            }}>Nuevo Pedido</p>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                            <IconButton
                                onClick={e => {
                                    history.push("/pedidos")
                                }}
                            ><AssignmentIcon style={{ color: "#A2A2A2", width: "26px", heigth: "26px", }} /></IconButton>
                            <p style={{
                                fontFamily: "Lexend",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "8px",
                                lineHeight: "10px",
                                color: "#838383",
                                margin: 0,
                            }}>Pedidos</p>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                            <IconButton onClick={signOut}><LogoutIcon style={{ color: "#A2A2A2", width: "26px", heigth: "26px", }} /></IconButton>
                            <p style={{
                                fontFamily: "Lexend",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "8px",
                                lineHeight: "10px",
                                color: "#838383",
                                margin: 0,
                            }}>Cerrar Sesión</p>
                        </div>
                    </div>
            }
        </>
    )
}

export default Menu