import React from "react"
import moment from 'moment'

const Mes = ({ value, onChange }) => {
    const getMenuItems = () => {
        let menuItems = [];
        for (let index = 0; index < 12; index++) {
            menuItems.push(
                <option key={index} value={index + 1}>{moment().month(index).format("MMM")}</option>
            );
        }
        return menuItems
    }
    return (
        <select name="select"
            style={{
                fontFamily: 'Lexend',
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "15px",
                height: "50px",
                width: "100%",
                "-webkit-appearance": "none",
                "-webkit-box-shadow": "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
                textAlign: "center",
                color: "#838383",
                width: "100%",
                padding: "10px",
                border: "none",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
                borderRadius: "8px",
                background: "#F7F7F7",
                outline: "none",
                textAlign: "left",
            }}
            value={value}
            onChange={onChange}
        >
            <option value={""} disabled selected hidden>Mes</option>
            {getMenuItems()}
        </select >
    )
}

export default Mes