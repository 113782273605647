import React, { useState } from "react";
import { Grid, Hidden } from "@mui/material";
import { obtenerErrorFirebase } from "../const/erroresFirebase";
import { useAuth } from "../hooks/useAuth";
import Login from "../containers/Login";

export default function SignIn() {
  const { signIn, errorFirebase } = useAuth();
  const [correo, setCorreo] = useState("");
  const [clave, setClave] = useState("");

  const onSubmitClicked = async () => {
    try {
      window.localStorage.clear();
      await signIn(correo, clave);
    } catch (error) { }
  };

  return (
    <div>
      <Grid container justifyContent={"center"} alignItems={"center"} style={{ height: "80%" }}>
        <Grid item xs={10} sm={6} md={4} lg={3}>
          <Login
            onClick={onSubmitClicked}
            correo={correo}
            clave={clave}
            setCorreo={setCorreo}
            setClave={setClave}
            errorFirebase={errorFirebase}
            obtenerErrorFirebase={obtenerErrorFirebase}
          />
        </Grid>
      </Grid>
    </div>
  );
}
