import React from 'react'

const Articulo = ({ dato, onClick = () => { } }) => {
    console.log(dato)
    return (
        <div style={{
            width: "100%",
            borderRadius: "15px",
            height: "107px",
            position: "relative",
            cursor: "pointer"
        }} onClick={onClick}>
            <p style={{
                position: "absolute",
                bottom: "10px",
                left: "10px",
                fontFamily: "Lexend",
                fontWeight: 500,
                fontSize: "14px",
                color: "#F6F6F6",
                textShadow: "1px 1px 2px rgba(0, 0, 0, 0.93)",
                margin: "0",
            }}>{dato?.nombre}</p>
            <img style={{
                height: "100%",
                width: "100%",
                borderRadius: "15px",
                objectFit: "cover",
                objectPosition: "center"
            }} src={dato?.archivo?.url} />
        </div>
    )
}

export default Articulo