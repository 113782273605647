import React from "react";
const TextArea = ({
    disabled = false,
    placeholder,
    valor,
    rows = 1,
    label,
    type = "text",
    min = null,
    onChange = () => { },
    onKeyDown,
}) => {
    return (
        <>
            <div style={{ width: "100%" }}>
                <p style={{
                    margin: "0",
                    fontSize: "12px",
                    fontFamily: "Lexend",
                    fontWeight: 400,
                    color: "#838383",
                    marginBottom: "5px",
                }}>{label}</p>
                <textarea
                    disabled={disabled}
                    rows={rows}
                    style={{
                        padding: "11px 17px",
                        border: "8px",
                        background: disabled ? "#F6F6F6" : "#F6F6F6",
                        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
                        width: "100%",
                        color: disabled ? "#A9A9A9" : "#838383",
                        fontSize: "12px",
                        fontFamily: "Lexend",
                        fontWeight: 400,
                        cursor: disabled ? "default" : "text",
                        outline: "none",
                    }}
                    placeholder={placeholder}
                    value={valor}
                    lang="es-MX"
                    min={min}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    type={type}
                />
            </div>
        </>
    );
};

export default TextArea;
