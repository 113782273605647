import { CircularProgress, Grid } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import HomeComponent from '../../components/HomeComponent'
import moment from 'moment'
import Servo from '../../services/servo'
import Input from '../../components/Input'
import IconInput from '../../components/IconInput'
import Button from '../../components/Button'
import Camara from '../../components/Camara'
import { AiOutlineSearch } from 'react-icons/ai'
import { AiOutlinePlus } from 'react-icons/ai'
import Articulo from '../../components/Articulo'
import TextArea from '../../components/TextArea'
import { MdAttachMoney } from 'react-icons/md'
import FirebaseStorage from '../../services/firebase'
import Swal from "sweetalert2"
import { IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'
import { useMessages } from "../../hooks/useMessages"
import { useAlert } from '../../hooks/useAlert'
import { useHistory } from "react-router-dom"

const PedidosDetail = ({ match }) => {
    const id = match?.params?.id
    const isEditing = !!match?.params?.id
    console.log(!!match?.params?.id)
    const defaultArticulo = {
        nombre: "",
        precio: 0,
        precioPublico: 0,
        lugarCompra: "",
        fechaCompra: moment(new Date()).format("YYYY-MM-DD"),
        fechaImportacion: "",
        notas: "",
    }
    const [propiedades, setPropiedades] = useState({
        fechaPedido: moment(new Date()).format("YYYY-MM-DD"),
        fechaEntrega: "",
        solicitante: "",
        last: "",
    })
    const history = useHistory()
    const [besqueda, setBusqueda] = useState("")
    const [loading, setLoading] = useState(false)
    const { setMessages } = useMessages()
    const [articulo, setArticulo] = useState({ ...defaultArticulo })
    const { showAlert } = useAlert()
    const articuloRef = useRef(null)
    const [articulos, setArticulos] = useState([])
    const [photo, setPhoto] = useState(null)
    const [toShow, setToShow] = useState("")
    const [indexEdit, setIndexEdit] = useState(false)
    const [open, setOpen] = useState(false)
    const getLastId = async (_fecha = false) => {
        setLoading(true)
        let res = await Servo.get("pedidos/last")
        setPropiedades({
            ...propiedades,
            last: res?.cuerpo?.resultado[0]?.idPedido + 1,
        })
        setLoading(false)
    }
    const subirPedido = async () => {
        if (!articulos?.length > 0) {
            showAlert({
                message: "Se requiere por lo menos un artículo",
                severity: "warning"
            })
            return
        }

        setLoading(true)
        let res = await Servo.post("pedidos/guardar", {
            ...propiedades,
            articulos
        }, "pedidos", true, history, true)
        setLoading(false)

        if (res?.cuerpo) {
            setMessages(res?.cuerpo)
        }

    }
    const cancelarPedido = async () => {
        setLoading(true)
        let res = await Servo.post("pedidos/cancelar", { idPedido: id }, "pedidos", true, history, true)
        if (res?.cuerpo) {
            setMessages(res?.cuerpo)
        }
        setLoading(false)
    }
    const subirArticulo = async () => {
        if (articulo?.nombre != "") {
            if (indexEdit !== null && indexEdit !== false && indexEdit !== undefined) {
                articulos.splice(indexEdit, 1)
            }
            setLoading(true)
            // if (archivo) {
            //     await FirebaseStorage.delete(folder, archivo?.nombreFirebase)
            // }
            let _archivo = {}
            if (photo) {
                const blob = await new Promise((resolve) => photo.toBlob(resolve))
                console.log(blob)
                const folder = "Archivos"
                const ranm = Math.floor(Math.random() * 1000)
                const nombreFirebase =
                    "Articulo_" +
                    articulo?.nombre +
                    "_" +
                    moment(new Date()).format("DD_MM_YYYY_HH_mm_ss") +
                    "_" +
                    ranm +
                    "." +
                    blob?.type.split("/")[1]

                let url = await FirebaseStorage.upload(folder + "/" + nombreFirebase, blob)

                _archivo = {
                    url,
                    tipo: blob?.type,
                    folder,
                    nombre: nombreFirebase,
                    nombreFirebase,
                }
            } else {
                if (indexEdit) {
                    _archivo = { ...articulo?.archivo }
                } else {
                    _archivo = {
                        url: "https://firebasestorage.googleapis.com/v0/b/react-ventas.appspot.com/o/Sistema%2Fimagen-articulo.png?alt=media&token=88d684a0-dee4-4b9d-8323-87ad170d78cf",
                        tipo: "image/png",
                        folder: "Sistema",
                        nombre: "imagen-articulo.png",
                        nombreFirebase: "imagen-articulo.png",
                    }
                }
            }
            let copyArticulos = [...articulos]
            copyArticulos.push({
                ...articulo,
                archivo: _archivo
            })
            setArticulos([...copyArticulos])
            setArticulo({ ...defaultArticulo })
            showAlert({
                message: "Artículo Agregado",
                severity: "success"
            })
            setLoading(false)
            setOpen(false)
        } else {
            showAlert({
                message: "El nombre del articulo es requerido",
                severity: "warning"
            })
        }
    }
    const filtro = (_datos) => {
        let datosFiltrados = _datos.filter(dato => dato?.nombre?.toLowerCase()?.includes(besqueda?.toLowerCase()))
        return datosFiltrados
    }
    const getArticulosFormat = (_articulos) => {
        let format = []
        if (_articulos?.length > 0) {
            _articulos.forEach(articulo => {
                format.push({
                    ...articulo,
                    fechaCompra: moment(articulo?.fechaCompra).format("YYYY-MM-DD"),
                    fechaImportacion: (articulo?.fechaImportacion != null && articulo?.fechaImportacion != undefined) ? moment(articulo?.fechaImportacion).format("YYYY-MM-DD") : "",
                })
            })
        }
        return format
    }
    const getDatos = async () => {
        setLoading(true)
        let res = await Servo.get("pedidos?idPedido=" + id + "&expand=articulos.archivo")
        setPropiedades({
            ...res?.cuerpo?.resultado[0],
            fechaPedido: moment(res?.cuerpo?.resultado[0]?.fechaPedido).format("YYYY-MM-DD"),
            fechaEntrega: (articulo?.fechaEntrega != null && articulo?.fechaEntrega != undefined) ? moment(articulo?.fechaEntrega).format("YYYY-MM-DD") : "",
        })
        let articulosFormat = getArticulosFormat(res?.cuerpo?.resultado[0]?.articulos)
        setArticulos([...articulosFormat])
        console.log(res)
        setLoading(false)
    }
    useEffect(() => {
        if (isEditing) {
            getDatos()
        } else {
            getLastId()
        }
    }, [])

    return (
        <>
            <Grid container justifyContent={"center"}>
                <Grid item xs={12} sm={6} md={4} lg={3} style={{ flexDirection: "column" }}>
                    <div style={{
                        padding: "55px 0 15px 15px"
                    }}>
                        <HomeComponent />
                    </div>
                    <div>
                        <p style={{
                            color: "#7B7B7B",
                            fontFamily: "Lexend",
                            fontStyle: "normal",
                            fontSize: "24px",
                            fontWeight: "700",
                            lineHeight: "30px",
                            padding: "0 0 0 15px",
                            margin: 0,
                        }}>{isEditing ? "Editar" : "Nuevo"} Pedido</p>
                    </div>
                    <div style={{
                        padding: "15px 15px 0 15px",
                    }}>
                        <Input label={"Cliente que solicitó"} valor={propiedades?.solicitante} placeholder={"Nombre"} onChange={e => { setPropiedades({ ...propiedades, solicitante: e.target.value }) }} />
                    </div>
                    <div style={{
                        padding: "15px 15px 0 15px",
                    }}>
                        <Input label={"ID"} placeholder={""} valor={isEditing ? propiedades?.idPedido : propiedades?.last} disabled />
                    </div>
                    <div style={{
                        padding: "15px 15px 0 15px",
                    }}>
                        <Input label={"Fecha del pedido"} placeholder={""} valor={propiedades?.fechaPedido} type='date' disabled />
                    </div>
                    <div style={{
                        padding: "15px 15px 0 15px",
                    }}>
                        <Input
                            label={"Fecha de entrega"}
                            placeholder={""}
                            valor={propiedades?.fechaEntrega}
                            type='date'
                            min={moment(new Date()).format("YYYY-MM-DD")}
                            onChange={e => {
                                setPropiedades({ ...propiedades, fechaEntrega: moment(e.target.value).format("YYYY-MM-DD") })
                            }}
                        />
                    </div>
                    <div>
                        <p style={{
                            color: "#7B7B7B",
                            fontFamily: "Lexend",
                            fontStyle: "normal",
                            fontSize: "24px",
                            fontWeight: "700",
                            lineHeight: "30px",
                            padding: "25px 0 0 15px",
                            margin: 0,
                        }}>Artículos</p>
                    </div>
                    <div style={{
                        padding: "15px 15px 0 15px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                    }}>
                        <IconInput type={"text"} value={besqueda} onChange={e => { setBusqueda(e.target.value) }} placeholder={"Buscar"} Icon={AiOutlineSearch} back />
                        <div style={{
                            width: "90px",
                            paddingLeft: "35px"
                        }}>
                            <Button label={<AiOutlinePlus />} onClick={() => {
                                setArticulo({ ...defaultArticulo })
                                setPhoto(null)
                                setToShow("")
                                setIndexEdit(false)
                                setOpen(true)
                            }} />
                        </div>
                    </div>
                    <div style={{
                        padding: "15px 15px 0 15px",
                        display: "flex",
                        flexDirection: "row",
                    }}>
                        {
                            !loading ?
                                <Grid container spacing={2}>
                                    {
                                        articulos && articulos?.length > 0 ? filtro(articulos).map((articulo, i) => {
                                            return (
                                                <Grid item xs={6}>
                                                    <Articulo dato={articulo} onClick={() => {
                                                        setArticulo(articulo)
                                                        setIndexEdit(i)
                                                        setToShow(articulo?.archivo?.url)
                                                        setOpen(true)
                                                    }} />
                                                </Grid>
                                            )
                                        })
                                            :
                                            <div style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                paddingTop: "20px"
                                            }}>
                                                <p style={{
                                                    color: "#7B7B7B",
                                                    fontFamily: "Lexend",
                                                    fontStyle: "normal",
                                                    fontSize: "17px",
                                                    fontWeight: "700",
                                                    lineHeight: "30px",
                                                    padding: "25px 0 0 15px",
                                                    margin: 0,
                                                }}>No hay artículos</p>
                                            </div>
                                    }
                                </Grid>
                                :
                                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", padding: "25px 0" }}>
                                    <CircularProgress />
                                </div>
                        }
                    </div>
                    <div style={{
                        padding: "15px 15px 0 15px",
                        display: "flex",
                        flexDirection: "row",

                    }}>
                        {
                            !loading
                            &&
                            <>
                                <Button red label={"Cancelar"} onClick={() => {
                                    history.push("/pedidos")
                                }} />
                                <div style={{ width: "20px" }}></div>
                                <Button label={"Guardar"} onClick={() => { subirPedido() }} />

                            </>

                        }
                    </div>
                    {
                        !loading &&
                        <>
                            {
                                isEditing &&
                                <div style={{
                                    padding: "15px 15px 30px 15px",
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <Button red label={"Cancelar Pedido"} onClick={() => {
                                        Swal.fire({
                                            title: '¿Deseas Cancelar el pedido?',
                                            showCancelButton: true,
                                            confirmButtonText: 'Si',
                                            confirmButtonColor: '#7FDD99',
                                            cancelButtonColor: '#FFF',
                                            cancelButtonText: '<p style="color:#7FDD99;margin:5px 0;">No</p>'
                                        }).then((result) => {
                                            cancelarPedido()
                                        })
                                    }} />
                                    <div style={{ width: "1px", height: "56px" }}></div>
                                </div>
                            }
                        </>
                    }
                </Grid >
            </Grid >
            {
                open &&
                <div style={{
                    position: "fixed",
                    inset: 0,
                    background: "#FFFFFF",
                    zIndex: 1000,
                    overflowY: "auto"
                }}>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                    }}>
                        <IconButton onClick={() => setOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Grid container justifyContent={"center"}>
                        <Grid ref={articuloRef} item xs={12} sm={6} md={4} lg={3} style={{ flexDirection: "column" }}>
                            <div>
                                <p style={{
                                    color: "#7B7B7B",
                                    fontFamily: "Lexend",
                                    fontStyle: "normal",
                                    fontSize: "24px",
                                    fontWeight: "700",
                                    lineHeight: "30px",
                                    padding: "0 0 0 15px",
                                    margin: 0,
                                }}>{indexEdit !== false ? "Editar" : "Nuevo"} Artículo</p>
                            </div>
                            <div style={{
                                padding: "15px 15px 0 15px",
                            }}>
                                <Input label={"Nombre del producto"} placeholder={"Nombre"} valor={articulo?.nombre} onChange={e => { setArticulo({ ...articulo, nombre: e.target.value }) }} />
                            </div>
                            <div style={{
                                padding: "15px 35px 0px 15px",
                                display: "flex"
                            }}>
                                <IconInput label={"Precio"} placeholder={""} type='number' valor={articulo?.precio} front Icon={MdAttachMoney} onChange={e => {
                                    console.log(e.target.value)
                                    setArticulo({ ...articulo, precio: e.target.value })
                                }} />

                            </div>
                            <div style={{
                                padding: "15px 35px 0px 15px",
                                display: "flex"
                            }}>
                                <IconInput label={"Precio más ganancia"} placeholder={""} type='number' valor={articulo?.precioPublico} front Icon={MdAttachMoney} onChange={e => {
                                    console.log(e.target.value)
                                    setArticulo({ ...articulo, precioPublico: e.target.value })
                                }} />

                            </div>
                            <div style={{
                                padding: "15px 15px 0 15px",
                            }}>
                                <Input label={"Lugar de compra"} placeholder={"Lugar"} valor={articulo?.lugarCompra} onChange={e => { setArticulo({ ...articulo, lugarCompra: e.target.value }) }} />
                            </div>
                            <div style={{
                                padding: "15px 15px 0 15px",
                            }}>
                                <Input
                                    label={"Fecha de Compra"}
                                    placeholder={""}
                                    type='date'
                                    valor={articulo?.fechaCompra}
                                    onChange={e => {
                                        setArticulo({ ...articulo, fechaCompra: moment(e.target.value).format("YYYY-MM-DD") })
                                    }}
                                />
                            </div>
                            <div style={{
                                padding: "15px 15px 0 15px",
                            }}>
                                <Input
                                    label={"Fecha de pase a México"}
                                    placeholder={""}
                                    type='date'
                                    valor={articulo?.fechaImportacion}
                                    onChange={e => {
                                        setArticulo({ ...articulo, fechaImportacion: moment(e.target.value).format("YYYY-MM-DD") })
                                    }}
                                />
                            </div>
                            <div style={{
                                padding: "15px 15px 0 15px",
                            }}>
                                <Camara photo={photo} setPhoto={setPhoto} toShow={toShow} setToShow={setToShow} />
                            </div>
                            <div style={{
                                padding: "15px 15px 0 15px",
                            }}>
                                <TextArea rows={5} valor={articulo?.notas} label={"Notas"} placeholder={""} onChange={e => { setArticulo({ ...articulo, notas: e.target.value }) }} />
                            </div>
                            <div style={{ padding: "15px 15px 0 15px", marginBottom: "150px" }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    {
                                        !loading
                                            ?
                                            <>
                                                <Button red label={"Cancelar"} onClick={() => {
                                                    setOpen(false)
                                                }} />
                                                <div style={{ width: "20px" }}></div>
                                                <Button label={"Guardar"} onClick={() => { subirArticulo() }} />
                                            </>
                                            : <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", padding: "25px 0" }}>
                                                <CircularProgress />
                                            </div>
                                    }
                                </div>
                                {
                                    !loading &&
                                    <>
                                        {
                                            indexEdit !== null && indexEdit !== false && indexEdit !== undefined &&
                                            <div style={{
                                                padding: "15px 15px 30px 15px",
                                                display: "flex",
                                                flexDirection: "row",
                                            }}>
                                                <Button red label={"Borrar Artículo"} onClick={() => {
                                                    Swal.fire({
                                                        title: '¿Deseas borrar el artículo?',
                                                        showCancelButton: true,
                                                        confirmButtonText: 'Si',
                                                        confirmButtonColor: '#7FDD99',
                                                        cancelButtonColor: '#FFF',
                                                        cancelButtonText: '<p style="color:#7FDD99;margin:5px 0;">No</p>'
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            articulos.splice(indexEdit, 1)
                                                            setOpen(false)
                                                            showAlert({
                                                                message: "Artículo borrado",
                                                                severity: "success"
                                                            })
                                                        }
                                                    })
                                                }} />
                                                <div style={{ width: "1px", height: "56px" }}></div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>

                        </Grid>
                    </Grid>
                </div>
            }
        </>
    )
}

export default PedidosDetail